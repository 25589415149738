import React from 'react'
import Layout from '../components/Layout'
import SEO from "../components/SEO"
import PageHero from "../components/PageHero"
import { Link } from "gatsby"
import { ImHome } from "react-icons/im"

const ErrorPage = (props) => {

    return (
        <>
            <Layout location={props.location}>
                <SEO title="404 Page Not Found" />{/* title, description, image, article */}
                <PageHero heading="404 Page Not Found" />
                <section className="container mx-auto max-w-7xl text-center pt-4">
                    <div className="text-left px-5 md:px-8 pt-4 pb-4">
                      <ul className="text-left font-mono text-xs md:text-base">
                        <li className="inline-block border-black border-r-2">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-2">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block">
                          <span className="px-2">404</span>
                        </li>
                      </ul>
                    </div>
                    <div className="flex flex-col lg:flex-row cmscontent">
                        <div className="p-6">
                            <h2>Oops! Sorry Page Not Found</h2>
                            <h3>We can't seem to find the page you're looking for.</h3>
                            <p>Here are some helpfull links instead:</p>
                            <h4 className="heading-box-text1 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/">
                                    <span className="pulse">Home</span>
                                </Link>
                            </h4>
                            <h4 className="heading-box-text2 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/about-rokit-media/">
                                    <span className="pulse">About Rokit Media</span>
                                </Link>
                            </h4>
                            <h4 className="heading-box-text3 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/web-design-services/">
                                    <span className="pulse">Web Design Services</span>
                                </Link>
                            </h4>
                            <h4 className="heading-box-text1 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/jamstack-eco-system/">
                                    <span className="pulse">JAMstack Eco-System</span>
                                </Link>
                            </h4>
                            <h4 className="heading-box-text2 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/blog/">
                                    <span className="pulse">Blog</span>
                                </Link>
                            </h4>
                            <h4 className="heading-box-text3 text-2xl md:text-3xl lg:text-4xl p-4">
                                <Link to="/contact-rokit-media/">
                                    <span className="pulse">Contact</span>
                                </Link>
                            </h4>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ErrorPage